<template>
  <div class="page-container">
    <div class="page">
      <div class="g-flex-row">
        <img src="@i/xc.png" class="icon" />
        <span class="ft30 cl-000">千川词霸</span>
      </div>
      <span class="yj-title">创意规划 不止于快</span>
      <span class="yj-tip">海量词库，优选算法，智能生成投放词</span>
      <!-- <router-link to="/index">
        <span class="yj-button mt20">立即使用</span>
      </router-link> -->
      <div class="yj-button disabled mt20">正在维护</div>
      <div class="mt10">客服电话：18767141341</div>
      <div class="img-bg">
        <div class="left-img"></div>
        <div class="middle-img"></div>
        <div class="right-img"></div>
      </div>
      <div class="g-introduce-container mt85">
        <div class="g-introduce-content">
          <span class="cl-click">轻松上手，创意无限</span>
          <p class="g-introduce-content-title mt20">创意</p>
          <p class="g-introduce-content-text mt30">
            添加三五描述词
          </p>
          <p class="g-introduce-content-text">顷刻间智能生成最相关的投放词条</p>
          <p class="g-introduce-content-text">新手小白也能轻松掌握</p>
          <span class="g-introduce-content-content mt50"
            >· 可手动增加或删减推广词</span
          >
          <span class="g-introduce-content-content "
            >· 优选算法，自动为你关联最合适的创意词条</span
          >
          <span class="g-introduce-content-content "
            >· 用大数据生成想不到的创意词条</span
          >
        </div>
        <img
          src="../../assets/images/product/case3.png"
          class="ml20"
          style="width:604px;height:320px"
        />
      </div>
      <div
        class="g-introduce-container mt85"
        style="flex-direction: row-reverse;"
      >
        <div class="g-introduce-content ml20">
          <span class="cl-click">便捷操作，提升效率</span>
          <p class="g-introduce-content-title mt20">操作</p>
          <p class="g-introduce-content-text mt30">
            一键添加创意词条，节省手动添加的时间
          </p>
          <p class="g-introduce-content-text">提升投手工作效率，聚焦投放效果</p>
          <span class="g-introduce-content-content mt30"
            >· 关联词条完成后可以直接导入千川关键词列表</span
          >
          <span class="g-introduce-content-content ">· 可手动增减关键词</span>
          <span class="g-introduce-content-content ">· 可选择导入列表</span>
        </div>
        <img
          src="../../assets/images/product/case4.png"
          style="width:604px;height:320px"
        />
      </div>
    </div>
    <div class="bottom-container">
      <span class="bottom-title">现在使用抖脉 开始创意投放</span>
      <router-link to="/index">
        <span class="bottom-button">立即使用</span>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  created () {},
  methods: {
    // ------------------ 点击方法
    // ------------------ 请求方法
    // ------------------ 其它
  }
}
</script>
<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 48px;
  .yj-title {
    font-size: 46px;
    font-weight: bold;
    color: #000000;
    line-height: 66px;
    margin-top: 27px;
  }
  .yj-tip {
    font-size: 18px;
    font-weight: 400;
    color: #86868b;
    margin-top: 20px;
  }
  .yj-button {
    width: 180px;
    height: 50px;
    background: #2878ff;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 22px;
    .pointer;
    &.disabled {
      background-color: #757575;
      cursor: not-allowed;
    }
  }
  .img-bg {
    height: 422px;
    width: 1155px;
    position: relative;
    margin-top: 44px;
    .left-img {
      width: 531px;
      height: 387px;
      border-radius: 31px;
      background: url('../../assets/images/case1.png');
      position: absolute;
      bottom: 0;
      left: 0;
      border: 3px #333 solid;
    }
    .middle-img {
      width: 644px;
      height: 422px;
      border-radius: 31px;
      background: url('../../assets/images/case1.png');
      position: absolute;
      bottom: 0;
      left: 250px;
      border: 3px #333 solid;
      z-index: 2;
    }
    .right-img {
      width: 531px;
      height: 387px;
      border-radius: 31px;
      background: url('../../assets/images/case1.png');
      position: absolute;
      bottom: 0;
      border: 3px #333 solid;
      right: 0;
    }
  }
}
.bottom-container {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/images/discover/bottom.png');
  margin-top: 96px;
  .bottom-title {
    font-size: 40px;
    font-weight: 800;
    color: #ffffff;
    margin-top: 126px;
  }
  .bottom-button {
    width: 254px;
    height: 59px;
    background: #ff2626;
    border-radius: 30px;
    color: #fff;
    font-size: 20px;
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
.icon {
  height: 32px;
  width: 32px;
  margin-right: 10px;
}
</style>
